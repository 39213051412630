import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    // { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    // { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    // { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    // + eptw routes
    { title: 'My ePTW', path: '/eptw/work-orders', icon: 'fas fa-paper-plane text-red', class: '' },
    { title: 'My Workers', path: '/eptw/workers', icon: 'fas fa-id-card-alt text-pink', class: '' },
    { title: 'Browse ePTW', path: '/eptw/work-orders/browse', icon: 'fas fa-briefcase text-info', class: '' },
    { title: 'Pending ePTW', path: '/eptw/pending-work-orders', icon: 'fas fa-clipboard-list text-orange', class: '' },
    { title: 'Statistics', path: '/eptw/statistics', icon: 'fas fa-chart-pie text-green', class: '' },
    { title: 'Map', path: '/eptw/map', icon: 'fas fa-map-marker-alt text-blue', class: '' },

    // + data management pages
    { title:  'Regions', path: '/data/department-mgmt/regions', icon: 'fas fa-map-marked-alt', class: '' },
    { title:  'Locations', path: '/data/department-mgmt/locations', icon: 'fas fa-map-marker-alt', class: '' },
    { title:  'Sites', path: '/data/department-mgmt/sites', icon: 'fas fa-map-marker-alt', class: '' },

    // + workflow managements
    { title:  'Workflows', path: '/data/approval-mgmt/workflows', icon: 'fas fa-building', class: '' },

    // + email templates
    { title:  'Email Templates', path: '/data/notification-mgmt/email-templates', icon: 'fas fa-envelope-open', class: '' },
    { title:  'Work Types', path: '/data/eptw/work-types', icon: 'fas fa-wrench', class: '' },

    { title:  'Worker Management', path: '/data/eptw/worker-management', icon: 'fas fa-user-friends', class: '' },
    { title:  'ePTW', path: '/data/eptw/work-orders', icon: 'fas fa-users-cog', class: '' },

  // + other sample pages
    { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public dataItems: any[];
  public isCollapsed = true;

  // + department managements module
  public departmentMenus = [
    { title:  'Regions', path: '/data/department-mgmt/regions', icon: 'fas fa-map-marked-alt', class: '' },
    { title:  'Locations', path: '/data/department-mgmt/locations', icon: 'fas fa-map-marker-alt', class: '' },
    { title:  'Sites', path: '/data/department-mgmt/sites', icon: 'fas fa-map-marker-alt', class: '' }
  ]

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem.path.startsWith('/eptw'));
    this.dataItems = ROUTES.filter(menuItem => menuItem.path.startsWith('/data'));
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
